import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { observable } from 'mobx';
import { observer } from 'mobx-react';

import User from '../../models/user';
import TopMenu from './TopMenu';
import Content from './Content';
import Profile from '../Profile';
import {
  ElectionView,
  ElectionVoting,
  ElectionCreate,
  Codes,
  Analytics,
  AnalyticsBreakDown,
  Mailer,
  Votes,
} from '../Election';
import AddUser from './AddUser';
import Modal from '../../components/Modal';

class Home extends Component {
  data = observable({
    showCreateUser: false
  });

  async componentDidMount() {
    try {
      await User.loadAccount();
    } catch (error) {
      NotificationManager.error('Initialization error. Please logout and login again');
    }
  }

  UNSAFE_componentWillMount() {
    document.body.className = "menu-position-top full-screen";
    document.getElementById('root').className = "all-wrapper solid-bg-all";
  }

  handleAction(action) {
    switch (action) {
      case 'createUser':
        this.data.showCreateUser = true;
        break;
      default:
    }
  }

  render() {
    if (!User.loggedIn) {
      return (
        <Redirect to='/login' />
      );
    }

    return (
      <div className="layout-w">
        <Modal id="addUser" show={this.data.showCreateUser} onClose={() => this.data.showCreateUser = false}>
          <AddUser
            onCreate={() => this.data.showCreateUser = false}
          />
        </Modal>
        <TopMenu path={this.props.match.path} onAction={this.handleAction.bind(this)} />
        <div className="content-w">
          <div className="content-i">
            <div className="content-box">
              <div className="element-wrapper">
                <Route path='/home' exact component={Content} />
                <Route path='/home/profile' exact component={Profile} />
                <Route path='/home/mailer' exact component={Mailer} />
                <Route path='/home/elections/:id' exact component={ElectionView} />
                <Route path='/home/elections/:id/vote' exact component={ElectionVoting} />
                <Route path='/home/create-election' exact component={ElectionCreate} />
                <Route path='/home/elections/:id/codes' exact component={Codes} />
                <Route path='/home/elections/:id/analytics' exact component={Analytics} />
                <Route path='/home/elections/:id/votes' exact component={Votes} />
                <Route path='/home/elections/:election/analytics/:candidate' exact component={AnalyticsBreakDown} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Home);

import React, { Component } from 'react';
import { observable } from 'mobx';

import User from '../../models/user';
import Manager from '../../models/manager';
import DataTable from '../../components/DataTable';
import WithURILoad from '../../components/WithURILoad';
import Modal from '../../components/Modal';
import { NotificationManager } from 'react-notifications';

class Votes extends Component {
  data = observable({
    refresh: Date.now(),
    title: '',
    election: '',
    source: 'apitest',
    showViewVoterImag: false,
    currentImage: '',
    currentSameVotes: [],
    showViewSameVoters: false,
    view: 1,
    busy: false,
  });

  componentDidMount() {
  }

  async cancelVotes(ids) {
    try {
      const { match: { params: { id } } } = this.props;
      this.data.busy = true;
      await Manager.cancelVotes(id, ids);
      NotificationManager.success('Votes cancelled');
      this.data.busy = false;
      this.data.refresh = Date.now();
    } catch (error) {
      NotificationManager.error(error.message);
      this.data.busy = false;
    }
  }

  async cancelSameDeviceVotes() {
    try {
      const { match: { params: { id } } } = this.props;
      this.data.busy = true;
      await Manager.cancelSameDeviceVotes(id);
      NotificationManager.success('Votes cancelled');
      this.data.busy = false;
      this.data.refresh = Date.now();
    } catch (error) {
      NotificationManager.error(error.message);
      this.data.busy = false;
    }
  }

  render() {
    const { match: { params: { id } } } = this.props;
    return (
      <div>
        <Modal id="viewVoterImage" show={this.data.showViewVoterImag} onClose={() => this.data.showViewVoterImag = false}>
          <img src={this.data.currentImage} />
        </Modal>
        <Modal id="viewSameVoters" show={this.data.showViewSameVoters} onClose={() => this.data.showViewSameVoters = false}>
          <table>
            {
              this.data.currentSameVotes.map(x => (<tr>
                <td><img className='avatar-candidate' src={x.voterImage} /></td>
                <td>{`${x.account.firstName} ${x.account.lastName}`}</td>
                <td>{x.account.regNumber}</td>
                <td>{x.account.contactEmail}</td>
              </tr>))
            }
            <tr>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
              <td>&nbsp;</td>
            </tr>
          </table>
        </Modal>
        <div className='row'>
          <div className='col-md-12'>
            <div className='row'>
              {/* <div className='col-md-2'>&nbsp;</div> */}
              <WithURILoad
                URI="/votes"
                refresh={this.data.refresh}
                requestConfig={User.getRequestConfig({
                  query: { election: id, _count: true }
                })}
              >
                {
                  ({ URIData, URILoading, URIError, load }) => {
                    if (URILoading || URIError) return null;
                    const count = URIData;
                    return (
                      <>
                        <div className='col-md-4'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# " onClick={() => {
                            this.data.view = 1;
                            this.data.refresh = Date.now();
                          }}>
                            <div className="label">
                              Total Votes
                            </div>
                            <div className='text-success'>
                              {isNaN(count) ? 0 : count}
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                }
              </WithURILoad>
              <WithURILoad
                URI="/votes"
                refresh={this.data.refresh}
                requestConfig={User.getRequestConfig({
                  query: { election: id, cancelled: true, _count: true }
                })}
              >
                {
                  ({ URIData, URILoading, URIError, load }) => {
                    if (URILoading || URIError) return null;
                    const count = URIData;
                    return (
                      <>
                        <div className='col-md-4'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# " onClick={() => {
                            this.data.view = 1;
                            this.data.refresh = Date.now();
                          }}>
                            <div className="label">
                              Cancelled Votes
                            </div>
                            <div className='text-success'>
                              {isNaN(count) ? 0 : count}
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                }
              </WithURILoad>
              <WithURILoad
                URI="/votes/same_device_count"
                refresh={this.data.refresh}
                requestConfig={User.getRequestConfig({
                  query: { election: id, _count: true }
                })}
              >
                {
                  ({ URIData, URILoading, URIError, load }) => {
                    if (URILoading || URIError) return null;
                    const count = URIData;
                    return (
                      <>
                        <div className='col-md-4'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# " onClick={() => {
                            this.data.view = 2;
                            this.data.refresh = Date.now();
                          }}>
                            <div className="label">
                              Votes From Same Device
                            </div>
                            <div className='text-success'>
                              {isNaN(count) ? 0 : count}
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                }
              </WithURILoad>
            </div>
          </div>
        </div>
        <div className="row" >
          {
            (() => {
              switch (this.data.view) {
                case 1:
                  return (
                    <div className="col">
                      <h5 className="text-center">Votes</h5>
                      <DataTable
                        pageSize={10}
                        refresh={this.data.refresh}
                        path="/votes"
                        requestConfig={{ ...User.getRequestConfig({ query: { election: id, _populate: 'account,office,candidate' } }) }}
                        filterable
                        ignoreParsing={['user', 'candidate']}
                        columns={[
                          {
                            Header: 'Image',
                            accessor: 'voterImage',
                            filterable: false,
                            Cell: (row) => {
                              return (
                                <div className='text-center'>
                                  <a className="profile-tile-box" href="# " onClick={() => {
                                    this.data.currentImage = row.original.voterImage;
                                    this.data.showViewVoterImag = true;
                                  }}>
                                    <div className="pt-avatar-w">
                                      <img className='avatar-candidate' src={row.original.voterImage} />
                                    </div>
                                  </a>
                                </div>
                              )
                            },
                          },
                          {
                            Header: 'Email',
                            accessor: 'account.contactEmail',
                            filterable: false,
                          },
                          {
                            Header: 'Reg No',
                            accessor: 'account.regNumber',
                            filterable: false
                          },
                          {
                            Header: 'Office',
                            accessor: 'office.title',
                            filterable: false
                          },
                          {
                            Header: 'Candidate',
                            accessor: 'candidate',
                            filterable: false,
                            Cell: (row) => {
                              return (
                                <div className='text-center'>
                                  {`${row.original.candidate.firstName} ${row.original.candidate.lastName}`}
                                </div>
                              )
                            },
                          },
                          {
                            Header: 'Cancelled',
                            accessor: 'cancelled',
                            filterable: true,
                            Cell: (row) => {
                              return (
                                <div className='text-center'>
                                  {
                                    row.original.cancelled ? (
                                      <button className='btn btn-sm btn-warning'>true</button>
                                    ) : (
                                      <button className='btn btn-sm btn-success'>false</button>
                                    )
                                  }
                                </div>
                              )
                            },
                          },
                        ]}
                      />
                    </div>
                  );
                case 2:
                  return (
                    <div className="col">
                      <h5 className="text-center">Votes From Save Device (<button disabled={this.data.busy} className='btn btn-sm btn-warning' onClick={() => this.cancelSameDeviceVotes()}>cancel all</button>)</h5>
                      <DataTable
                        pageSize={10}
                        refresh={this.data.refresh}
                        path="/votes/same_device"
                        requestConfig={{ ...User.getRequestConfig({ query: { election: id, _populate: 'account,office,candidate' } }) }}
                        filterable
                        ignoreParsing={['user', 'candidate']}
                        columns={[
                          {
                            Header: 'Candidate',
                            accessor: 'candidate',
                            filterable: false,
                            Cell: (row) => {
                              return (
                                <div className='text-center'>
                                  {`${row.original.candidate.firstName} ${row.original.candidate.lastName}`}
                                </div>
                              )
                            },
                          },
                          {
                            Header: 'Office',
                            accessor: 'office.title',
                            filterable: false
                          },
                          {
                            Header: 'Votes',
                            accessor: 'votes',
                            filterable: false,
                            Cell: (row) => {
                              console.log(row);
                              if (row.original.votes) {
                                return (
                                  <div className='text-center'>
                                    {row.original.votes.length}
                                  </div>
                                )
                              }
                              return (
                                <div className='text-center'></div>
                              );
                            },
                          },
                          {
                            Header: 'Voter Details',
                            accessor: 'votes',
                            filterable: false,
                            Cell: (row) => {
                              if (row.original.votes) {
                                return (
                                  <a href='#' onClick={() => {
                                    this.data.currentSameVotes = row.original.votes;
                                    this.data.showViewSameVoters = true;
                                  }}>
                                    <div className='text-center'>
                                      {
                                        row.original.votes.slice(0, 5).map(x => (<img className='avatar-candidate' src={x.voterImage} />))
                                      }
                                    </div>
                                  </a>
                                )
                              }
                              return (
                                <div className='text-center'></div>
                              );
                            },
                          },
                          {
                            Header: 'Action',
                            accessor: 'votes',
                            filterable: false,
                            Cell: (row) => {
                              return (
                                <div className='text-center'>
                                  <button disabled={this.data.busy} className='btn btn-sm btn-warning' onClick={() => this.cancelVotes(row.original.votes.slice(1).map(x => x._id))}>cancel</button>
                                </div>
                              );
                            },
                          },
                        ]}
                      />
                    </div>
                  );
              }
            })()
          }
        </div>
      </div>
    );
  }
}

export default Votes;

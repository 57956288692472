import React, { Component } from 'react';
import { observable } from 'mobx';
import { capitalize } from 'lodash';
import { NotificationManager } from 'react-notifications';
import Dropzone from 'react-dropzone';

import User from '../../models/user';
import Manager from '../../models/manager';
import DataTable from '../../components/DataTable';
import WithURILoad from '../../components/WithURILoad';
import Variables from '../../variables';

class Codes extends Component {
  data = observable({
    refresh: Date.now(),
    title: '',
    election: '',
    source: 'apitest',
    modeList: [],
    years: [],
    showUpload: false,
    uploading: false,
  });

  componentDidMount() {
    this.reader = new FileReader();
    this.loadStats = () => { console.log('loading stats') };
  }

  async handleUpload(file) {
    this.data.uploading = true;
    try {
      this.reader.readAsDataURL(file);
      const { url } = await User.uploadImage(file);
      this.data.source = url;
    } catch (error) {
      NotificationManager.error(error.message);
    }
    this.data.uploading = false;
  }

  async generate() {
    try {
      const { match: { params: { id } } } = this.props;
      await Manager.generateCodes({
        election: id,
        title: this.data.title,
        mode: this.data.mode,
        modeList: this.data.modeList,
        source: this.data.source,
        years: this.data.years,
      });
      NotificationManager.success('Code generation queued. Refresh table to see new codes');
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  render() {
    const { match: { params: { id } } } = this.props;
    return (
      <div>
        <div className="row align-items-center mb-2">
          <div className="col-md-3 padded">
            <input
              className="form-control"
              placeholder="Email Title"
              onChange={(e) => this.data.title = e.target.value}
            />
          </div>
          <div className="col-md-3 padded">
            <select
              className="form-control"
              placeholder="Data Source"
              onChange={(e) => {
                this.data.showUpload = e.target.value === 'upload'
                this.data.source = e.target.value === 'upload' ? '' : e.target.value;
              }}
            >
              <option value="">Select Data Source</option>
              {/* <option value="api">Student Data API</option>
              <option value="apitest">Student Data API(Test)</option> */}
              <option value="upload">Excel Sheet</option>
            </select>
          </div>
          <div className="col-md-3 padded">
            <select
              className="form-control"
              placeholder="Mode"
              onChange={(e) => this.data.mode = e.target.value}
            >
              <option value="">Select Mode</option>
              <option value="exempt">Exempt {capitalize(Variables.CODE_FILTER)}</option>
              <option value="include">Include {capitalize(Variables.CODE_FILTER)}</option>
            </select>
          </div>
          <div className="col-md-3 padded">
            <input
              className="form-control"
              placeholder={`${capitalize(Variables.CODE_FILTER)} Codes (comma seperated)`}
              onChange={(e) => this.data.modeList = e.target.value.split(',').map(a => a.trim())}
            />
          </div>
        </div>
        <div className="row align-items-center mb-2">
          <div className="col-md-3 padded">
            {/* <input
              className="form-control"
              placeholder="Reg Years (comma seperated)"
              onChange={(e) => this.data.years = e.target.value.split(',').map(a => a.trim())}
            /> */}
          </div>
          <div className="col-md-3 padded">
            {
              this.data.showUpload && (
                <Dropzone
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onDrop={(files) => {
                    this.handleUpload(files[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <input
                          className="form-control"
                          placeholder="Click Here To Upload"
                          readOnly
                          value={this.data.source.startsWith('http') ? this.data.source : 'Click Here To Upload'}
                        />
                      </div>
                    </section>
                  )}
                </Dropzone>
              )
            }
          </div>
          <div className="col-md-3 padded">
          </div>
          <div className="col-md-3 padded text-right">
            <button
              className="btn btn-primary btn-rounded animated bounce"
              onClick={() => {
                this.data.refresh = Date.now();
                if (this.loadStats) this.loadStats();
              }}
            >
              <i className="os-icon os-icon-grid-18"></i>
              <span>Refresh Data</span>
            </button>
            <button
              className="btn btn-primary btn-rounded animated bounce"
              onClick={this.generate.bind(this)}
              disabled={Manager.generatingCodes || this.data.uploading}
            >
              <i className="os-icon os-icon-ui-15"></i>
              <span>Generate</span>
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-10'>
            <div className='row'>
              <div className='col-md-2'>&nbsp;</div>
              <WithURILoad
                URI="/registrationCodes/analytics"
                refresh={this.data.refresh}
                requestConfig={User.getRequestConfig({
                  query: { election: id }
                })}
              >
                {
                  ({ URIData, URILoading, URIError, load }) => {
                    if (URILoading || URIError) return null;
                    this.loadStats = load;
                    const {
                      generated,
                      used,
                      sent,
                      delivered,
                      cancelledSent,
                      cancelledDelivered,
                    } = URIData;
                    return (
                      <>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Generated Codes
                            </div>
                            <div className='text-success'>
                              {generated}
                            </div>
                          </a>
                        </div>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Sent Codes
                            </div>
                            <div className='text-success'>
                              {sent}
                            </div>
                          </a>
                        </div>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Delivered Codes
                            </div>
                            <div className='text-success'>
                              {delivered}
                            </div>
                          </a>
                        </div>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Used Codes
                            </div>
                            <div className='text-success'>
                              {used}
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                }
              </WithURILoad>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h5 className="text-center">Generated Codes</h5>
            <DataTable
              pageSize={10}
              refresh={this.data.refresh}
              path="/registrationCodes/withAnalytics"
              requestConfig={{ ...User.getRequestConfig({ query: { election: id } }) }}
              filterable
              ignoreParsing={['user']}
              columns={[
                {
                  Header: 'Email',
                  accessor: 'email',
                  filterable: true,
                },
                {
                  Header: 'Password',
                  accessor: 'password',
                  filterable: false
                },
                {
                  Header: 'Description',
                  accessor: 'description',
                  filterable: false
                },
                {
                  Header: 'Email Sent',
                  accessor: 'sent',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.mails.length > 0 ? (
                            <div className="value badge badge-pill badge-info">
                              Sent
                            </div>
                          ) : (
                            <div className="value badge badge-pill badge-warning">
                              Pending
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                },
                {
                  Header: 'Email Statuses',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.mails.length > 0 ? (
                            <>
                              {
                                row.original.mails[0].delivery_statuses.indexOf('delivered') >= 0 ? (
                                  <div className={`value badge badge-pill badge-success`}>
                                    delivered
                                  </div>
                                ) : (
                                  <div className={`value badge badge-pill badge-success`}>
                                    pending
                                  </div>
                                )
                              }
                            </>
                          ) : (
                            <div className="value badge badge-pill badge-warning">
                              Pending
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                },
                {
                  Header: 'Status',
                  accessor: 'status',
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">Show All</option>
                      <option value="NEW">NEW</option>
                      <option value="USED">USED</option>
                    </select>
                  )
                },
                {
                  Header: 'Is Duplicate Email',
                  accessor: 'isDuplicateEmail',
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.isDuplicateEmail ? (
                            <div className="value badge badge-pill badge-warning">
                              TRUE
                            </div>
                          ) : (
                            <div className="value badge badge-pill badge-info">
                              FALSE
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                  Filter: ({ filter, onChange }) => (
                    <select
                      onChange={event => onChange(event.target.value)}
                      style={{ width: "100%" }}
                      value={filter ? filter.value : ""}
                    >
                      <option value="">Show All</option>
                      <option value="true">TRUE</option>
                      <option value="false">FALSE</option>
                    </select>
                  )
                },
              ]}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default Codes;

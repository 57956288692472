import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { get, pick } from 'lodash';
import { NotificationManager } from 'react-notifications';
import Dropzone from 'react-dropzone';

import User from '../../models/user';
import Manager from '../../models/manager';

import './style.css';

const validateObject = (keys, obj) => {
  for (let i = 0; i < keys.length; i += 1) {
    const prop = keys[i];
    if (!obj[prop]) {
      NotificationManager.error(`${prop} is required`);
      return false;
    }
  }
  return true;
}

class ManageCandidate extends Component {
  data = observable({
    error: false,
    loading: true,
    created: undefined,
  });

  componentDidMount() {
    this.reader = new FileReader();

    this.reader.addEventListener("load", () => {
      this.data.avatarPreview = this.reader.result;
    }, false);
  }

  handleImage(file) {
    this.reader.readAsDataURL(file);
    this.data.avatarFile = file;
  }

  async create() {
    try {
      const data = toJS(this.data);
      const dataKeys = ['firstName', 'lastName', 'regNumber'];
      if (!validateObject(dataKeys, data)) return;
      const createData = {
        ...pick(data, ['firstName', 'lastName', 'regNumber']),
        office: this.props.office,
        election: Manager.currentElection._id,
      };
      if (data.avatarFile) {
        createData.avatar = await User.uploadImage(data.avatarFile);
      }
      const candidate = await Manager.createCandidate(createData);
      this.props.onCreate && this.props.onCreate(candidate);
      NotificationManager.success('candidate created');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async update() {
    try {
      const data = toJS(this.data);
      const updateData = {
        ...pick(this.props.candidate, ['firstName', 'lastName', 'regNumber']), ...pick(data, ['firstName', 'lastName', 'regNumber']),
      };
      const dataKeys = ['firstName', 'lastName', 'regNumber'];
      if (!validateObject(dataKeys, updateData)) return;
      if (data.avatarFile) {
        updateData.avatar = await User.uploadImage(data.avatarFile);
      }
      const candidate = await Manager.updateCandidate(this.props.candidate._id, updateData);
      this.props.onUpdate && this.props.onUpdate(candidate);
      NotificationManager.success('candidate updated');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async delete() {
    try {
      const updateData = {
        status: 'DELETED',
      };
      const candidate = await Manager.updateCandidate(this.props.candidate._id, updateData);
      this.props.onDelete && this.props.onDelete(candidate);
      NotificationManager.success('candidate deleted');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    const { candidate } = this.props;
    return (
      <div className="row padded">
        <div className="col padded">
          <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
            <div className="element-info">
              <div className="element-info-with-icon">
                <div className="element-info-icon">
                  <div className="os-icon os-icon-fingerprint"></div>
                </div>
                <div className="element-info-text">
                  <h5 className="element-inner-header">
                    Candidate Management Form
                  </h5>
                  <div className="element-inner-desc">
                    Ensure you fill in the correct data
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 text-center">
              <div className="avatar-wrapper">
                <img alt="" src={this.data.avatarPreview || get(candidate, 'avatar.url') || '/img/avatar1.jpg'} />
                <Dropzone
                  accept="image/jpeg, image/png"
                  onDrop={(files) => {
                    this.handleImage(files[0]);
                  }}
                >
                  {
                    ({ getRootProps, getInputProps }) => (
                      <div className="avatar-backdrop" {...getRootProps()}>
                        <input {...getInputProps()} />
                      </div>
                    )
                  }
                </Dropzone>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">First Name</label>
                  <input
                    className="form-control"
                    placeholder="First Name"
                    defaultValue={get(candidate, 'firstName')}
                    onChange={(e) => this.data.firstName = e.target.value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Last Name</label>
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    defaultValue={get(candidate, 'lastName')}
                    onChange={(e) => this.data.lastName = e.target.value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Reg Number</label>
                  <input
                    className="form-control"
                    placeholder="Reg Number"
                    defaultValue={get(candidate, 'regNumber')}
                    onChange={(e) => this.data.regNumber = e.target.value}
                  />
                </div>
                <div className="form-buttons-w">
                  {
                    this.props.candidate && (
                      <button
                        className="btn btn-primary"
                        onClick={this.update.bind(this)}
                        disabled={Manager.updatingCandidate || User.uploading}
                      >
                        Update
                      </button>
                    )
                  }
                  {
                    this.props.candidate && (
                      <button
                        className="btn btn-danger"
                        onClick={this.delete.bind(this)}
                        disabled={Manager.updatingCandidate || User.uploading}
                      >
                        Delete
                      </button>
                    )
                  }
                  {
                    !this.props.candidate && (
                      <button
                        className="btn btn-primary"
                        onClick={this.create.bind(this)}
                        disabled={Manager.creatingCandidate || User.uploading}
                      >
                        Create
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ManageCandidate;

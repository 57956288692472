import React, { Component } from 'react';
import { observable } from 'mobx';
import { NotificationManager } from 'react-notifications';

import Modal from '../../components/Modal';
import Manager from '../../models/manager';
import MailSend from './MailSend';
import User from '../../models/user';
import DataTable from '../../components/DataTable';
import WithURILoad from '../../components/WithURILoad';

class Mailer extends Component {
  data = observable({
    refresh: Date.now(),
    showMailSend: false,
    currentManager: '',
    resending: false,
  });

  componentDidMount() {

  }

  async resendMail(manager) {
    try {
      this.data.resending = true;
      await Manager.resendMail(manager);
      NotificationManager.success('Email resend queued. Refresh table to see new mails');
      this.data.resending = false;
    } catch (error) {
      NotificationManager.error(error.message);
      this.data.resending = false;
    }
  }

  render() {
    return (
      <div>
        <Modal id="mailsend" show={this.data.showMailSend} onClose={() => this.data.showMailSend = false}>
          <MailSend onClose={() => this.data.showMailSend = false} />
        </Modal>
        <div className="row align-items-center mb-2">
          <div className="col-md-4 padded">
            <button
              className="btn btn-primary btn-rounded animated bounce"
              onClick={() => this.data.showMailSend = true}
            >
              <i className="os-icon os-icon-mail"></i>
              <span>Send New</span>
            </button>
          </div>
          <div className="col-md-4 padded">
          </div>
          <div className="col-md-4 padded text-right">
            <button
              className="btn btn-primary btn-rounded animated bounce"
              onClick={() => {
                this.data.refresh = Date.now();
              }}
            >
              <i className="os-icon os-icon-grid-18"></i>
              <span>Refresh Data</span>
            </button>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-10'>
            <div className='row'>
              <div className='col-md-2'>&nbsp;</div>
              <WithURILoad
                URI="/mailers/analytics"
                refresh={this.data.refresh}
                requestConfig={User.getRequestConfig({
                  query: {
                    manager: this.data.currentManager,
                  }
                })}
              >
                {
                  ({ URIData, URILoading, URIError, load }) => {
                    if (URILoading || URIError) return null;
                    this.loadStats = load;
                    const {
                      generated,
                      sent,
                      delivered,
                    } = URIData;
                    return (
                      <>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Generated Mails
                            </div>
                            <div className='text-success'>
                              {generated}
                            </div>
                          </a>
                        </div>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Sent Mails
                            </div>
                            <div className='text-success'>
                              {sent}
                            </div>
                          </a>
                        </div>
                        <div className='col-md-2'>
                          <a className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                            <div className="label">
                              Delivered Mails
                            </div>
                            <div className='text-success'>
                              {delivered}
                            </div>
                          </a>
                        </div>
                      </>
                    )
                  }
                }
              </WithURILoad>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-7">
            <h5 className="text-center">Mail Send Tasks</h5>
            <DataTable
              pageSize={10}
              refresh={this.data.refresh}
              path="/mailerManagers"
              requestConfig={{
                ...User.getRequestConfig({
                  query: {
                    _sort: '_id:-1',
                  }
                })
              }}
              filterable
              ignoreParsing={['user']}
              columns={[
                {
                  Header: 'Identifier',
                  accessor: 'identifier',
                  filterable: true,
                },
                {
                  Header: 'Title',
                  accessor: 'title',
                  filterable: true
                },
                {
                  Header: 'Body',
                  accessor: 'body',
                  filterable: false
                },
                {
                  Header: 'Sent',
                  accessor: 'sent',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.sent ? (
                            <div className="value badge badge-pill badge-info">
                              Sent
                            </div>
                          ) : row.original.hasError ? (
                            <div className="value badge badge-pill badge-danger">
                              Resend
                            </div>
                          ) : (
                            <div className="value badge badge-pill badge-warning">
                              Pending
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                },
                {
                  Header: 'Action',
                  accessor: 'identifier',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        <button
                          className={`btn btn-sm ${this.data.currentManager === row.original._id ? 'btn-success' : 'btn-default'}`}
                          onClick={() => {
                            this.data.currentManager = row.original._id;
                            this.data.refresh = Date.now();
                          }}
                        >
                          {this.data.currentManager === row.original._id ? 'active' : 'view'}
                        </button>
                        <button
                          className={`btn btn-sm btn-default`}
                          disabled={this.data.resending}
                          onClick={() => {
                            this.resendMail(row.original._id);
                          }}
                        >
                          resend
                        </button>
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
          <div className="col-md-5">
            <h5 className="text-center">Generated Mails</h5>
            <DataTable
              pageSize={10}
              refresh={this.data.refresh}
              path="/mailers/withAnalytics"
              requestConfig={{ ...User.getRequestConfig({ query: { manager: this.data.currentManager } }) }}
              filterable
              ignoreParsing={['user']}
              columns={[
                {
                  Header: 'Email',
                  accessor: 'email',
                  filterable: true,
                },
                {
                  Header: 'Email Sent',
                  accessor: 'sent',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.mails.length > 0 ? (
                            <div className="value badge badge-pill badge-info">
                              Sent
                            </div>
                          ) : (
                            <div className="value badge badge-pill badge-warning">
                              Pending
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                },
                {
                  Header: 'Email Statuses',
                  filterable: false,
                  Cell: (row) => {
                    return (
                      <div className='text-center'>
                        {
                          row.original.mails.length > 0 ? (
                            <>
                              {
                                row.original.mails[0].delivery_statuses.indexOf('delivered') >= 0 ? (
                                  <div className={`value badge badge-pill badge-success`}>
                                    delivered
                                  </div>
                                ) : (
                                  <div className={`value badge badge-pill badge-success`}>
                                    pending
                                  </div>
                                )
                              }
                            </>
                          ) : (
                            <div className="value badge badge-pill badge-warning">
                              Pending
                            </div>
                          )
                        }
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>
        </div>

      </div>
    );
  }
}

export default Mailer;

import { observer } from 'mobx-react';

import ElectionViewComponent from './ElectionView';
import ElectionVotingComponent from './ElectionVoting';
import ElectionCreateComponent from './ElectionCreate';
import ManageCandidateComponent from './ManageCandidate';
import ManageOfficeComponent from './ManageOffice';
import DeleteComponent from './Delete';
import CodesComponent from './Codes';
import AnalyticsComponent from './Analytics';
import AnalyticsBreakDownComponent from './AnalyticsBreakDown';
import MailerComponent from './Mailer';
import VotesComponent from './Votes';

export const ElectionView = observer(ElectionViewComponent);
export const ElectionVoting = observer(ElectionVotingComponent);
export const ElectionCreate = observer(ElectionCreateComponent);
export const ManageCandidate = observer(ManageCandidateComponent);
export const ManageOffice = observer(ManageOfficeComponent);
export const Delete = observer(DeleteComponent);
export const Codes = observer(CodesComponent);
export const Analytics = observer(AnalyticsComponent);
export const AnalyticsBreakDown = observer(AnalyticsBreakDownComponent);
export const Mailer = observer(MailerComponent);
export const Votes = observer(VotesComponent);

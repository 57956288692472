import React, { Component } from 'react';
import { observable } from 'mobx';
import { NotificationManager } from 'react-notifications';
import { Redirect } from 'react-router-dom';

import Manager from '../../models/manager';

import './style.css';

class Delete extends Component {
  data = observable({
    error: false,
    deleted: false,
  });

  async delete() {
    try {
      await Manager.updateElection({
        title: `${Manager.currentElection.title}:DELETED on ${Date.now()}`, status: 'DELETED'
      });
      NotificationManager.success('Election deleted');
      this.props.onDelete && this.props.onDelete();
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  render() {
    return (
      <div className="row padded">
        <div className="col padded">
          <div className="element-info">
            <div className="element-info-with-icon">
              <div className="element-info-icon">
                <div className="os-icon os-icon-ui-15"></div>
              </div>
              <div className="element-info-text">
                <h5 className="element-inner-header">
                  Election Delete
                </h5>
                <div className="element-inner-desc">
                  Ensure you know what you're doing
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col">
              <p>Are you sure about this ?</p>
              <button
                className="btn btn-danger btn-rounded animated bounce"
                onClick={this.delete.bind(this)}
                disabled={Manager.updatingElection}
              >
                <i className="os-icon os-icon-ui-15"></i>
                <span>Proceed</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Delete;

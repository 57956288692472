import { observable, toJS } from 'mobx';
import { map, uniqBy } from 'lodash';

import { apiRequest, apiDownload } from '../services/request';
import variables from '../variables';

import User from './user';

const Manager = {
  loadingElection: false,
  loadingCurrentElections: false,
  loadingUpcomingElections: false,
  loadingHistoryElections: false,
  loadingCurrentElectionResults: false,
  downloadingElectionVotes: false,
  loadingOffices: false,
  creatingElection: false,
  creatingUser: false,
  creatingCandidate: false,
  creatingOffice: false,
  updatingCandidate: false,
  cancelingVotes: false,
  updatingOffice: false,
  updatingElection: false,
  updatingUser: false,
  voting: false,
  generatingCodes: false,
  sendingMail: false,
  offices: [],
  currentElection: {},
  currentElectionCandidates: [],
  currentElectionVotes: [],
  currentElectionResults: [],
  canParticipateInCurrentElection: false,
  currentElections: [],
  upcomingElections: [],
  historyElections: [],

  loadElection: async function (id) {
    try {
      this.loadingElection = true;
      this.currentElection = {};
      this.currentElectionCandidates = [];
      this.canParticipateInCurrentElection = false;
      this.currentElectionResults = [];
      const promises = [
        apiRequest('GET', `/elections/${id}`, {
          url: variables.API_URL,
          query: { _populate: 'offices' },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', '/candidates', {
          url: variables.API_URL,
          query: { election: id, status: 'ACTIVE' },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', `/elections/${id}/canParticipate`, {
          url: variables.API_URL,
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
        apiRequest('GET', '/results', {
          url: variables.API_URL,
          query: { election: id },
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        }),
      ];
      const [election, candidates, canParticipate, results] = await Promise.all(promises);
      this.currentElection = election;
      this.currentElectionCandidates = candidates;
      this.canParticipateInCurrentElection = canParticipate.success;
      this.currentElectionResults = results;
      this.loadingElection = false;
    } catch (error) {
      this.loadingElection = false;
      throw error;
    }
  },

  loadOffices: async function () {
    try {
      this.loadingOffices = true;
      const data = await apiRequest('GET', '/offices', {
        url: variables.API_URL,
        query: { status: 'DEFAULT' },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.offices = data;
      this.loadingOffices = false;
    } catch (error) {
      this.loadingOffices = false;
      throw error;
    }
  },

  createElection: async function (bodyData) {
    try {
      this.creatingElection = true;
      const body = {
        ...bodyData,
      };
      const data = await apiRequest('POST', '/elections', {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.offices = data;
      this.creatingElection = false;
      return data;
    } catch (error) {
      this.creatingElection = false;
      throw error;
    }
  },

  createOffice: async function (bodyData) {
    try {
      this.creatingOffice = true;
      const body = {
        ...bodyData,
      };
      const data = await apiRequest('POST', '/offices', {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.offices.push(data);
      this.creatingOffice = false;
      return data;
    } catch (error) {
      this.creatingOffice = false;
      throw error;
    }
  },

  updateElection: async function (bodyData) {
    try {
      this.updatingElection = true;
      const body = {
        ...bodyData,
      };
      const data = await apiRequest('PUT', `/elections/${this.currentElection._id}`, {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.loadElection(this.currentElection._id);
      this.updatingElection = false;
      return data;
    } catch (error) {
      this.updatingElection = false;
      throw error;
    }
  },

  updateOffice: async function (id, bodyData) {
    try {
      this.updatingOffice = true;
      const body = {
        ...bodyData,
      };
      const data = await apiRequest('PUT', `/offices/${id}`, {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      if (data.status !== 'DELETED') {
        this.offices = uniqBy([data, ...toJS(this.offices)], '_id');
      }
      this.updatingOffice = false;
      return data;
    } catch (error) {
      this.updatingOffice = false;
      throw error;
    }
  },

  createUser: async function (bodyData) {
    try {
      this.creatingUser = true;
      const data = await apiRequest('POST', '/signup', {
        url: variables.API_URL,
        body: bodyData,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.creatingUser = false;
      return data;
    } catch (error) {
      this.creatingUser = false;
      throw error;
    }
  },

  createCandidate: async function (bodyData) {
    try {
      this.creatingCandidate = true;
      const data = await apiRequest('POST', '/candidates', {
        url: variables.API_URL,
        body: bodyData,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.creatingCandidate = false;
      this.currentElectionCandidates.push(data);
      return data;
    } catch (error) {
      this.creatingCandidate = false;
      throw error;
    }
  },

  updateCandidate: async function (id, bodyData) {
    try {
      this.updatingCandidate = true;
      const body = {
        ...bodyData,
      };
      const data = await apiRequest('PUT', `/candidates/${id}`, {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElectionCandidates = uniqBy([data, ...toJS(this.currentElectionCandidates)], '_id');
      this.updatingCandidate = false;
      return data;
    } catch (error) {
      this.updatingCandidate = false;
      throw error;
    }
  },

  generateCodes: async function (requestData) {
    try {
      this.generatingCodes = true;
      const data = await apiRequest('POST', '/registrationCodes/generate', {
        url: variables.API_URL,
        body: requestData,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.generatingCodes = false;
      return data;
    } catch (error) {
      this.generatingCodes = false;
      throw error;
    }
  },

  sendMail: async function (requestData) {
    try {
      this.sendingMail = true;
      const data = await apiRequest('POST', '/mailerManagers', {
        url: variables.API_URL,
        body: requestData,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.sendingMail = false;
      return data;
    } catch (error) {
      this.sendingMail = false;
      throw error;
    }
  },

  resendMail: async function (manager) {
    try {
      this.sendingMail = true;
      const data = await apiRequest('PUT', `/mailerManagers/${manager}`, {
        url: variables.API_URL,
        body: {
          resend: true,
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.sendingMail = false;
      return data;
    } catch (error) {
      this.sendingMail = false;
      throw error;
    }
  },

  loadCurrentElectionResults: async function () {
    try {
      this.loadingCurrentElectionResults = true;
      const data = await apiRequest('GET', '/results', {
        url: variables.API_URL,
        query: { election: this.currentElection._id },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElectionResults = data;
      this.loadingCurrentElectionResults = false;
    } catch (error) {
      this.loadingCurrentElectionResults = false;
      throw error;
    }
  },

  loadCurrentElections: async function () {
    try {
      this.loadingCurrentElections = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: { $in: ['DEFAULT', 'CANCELLED'] },
          startDate: { $lte: Date.now() },
          endDate: { $gte: Date.now() }
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElections = data;
      this.loadingCurrentElections = false;
    } catch (error) {
      this.loadingCurrentElections = false;
      throw error;
    }
  },

  loadUpcomingElections: async function () {
    try {
      this.loadingUpcomingElections = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: { $in: ['DEFAULT', 'CANCELLED'] },
          startDate: { $gt: Date.now() },
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.upcomingElections = data;
      this.loadingUpcomingElections = false;
    } catch (error) {
      this.loadingUpcomingElections = false;
      throw error;
    }
  },

  loadElectionHistory: async function () {
    try {
      this.loadingElection = true;
      const data = await apiRequest('GET', '/elections', {
        url: variables.API_URL,
        query: {
          status: { $in: ['DEFAULT', 'CANCELLED'] },
          endDate: { $lt: Date.now() },
          _sort: 'createdAt:-1',
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.historyElections = data;
      this.loadingElection = false;
    } catch (error) {
      this.loadingElection = false;
      throw error;
    }
  },

  /**
   * 
   * @param {Array<{ String: String }>} data 
   */
  vote: async function (data) {
    try {
      this.voting = true;
      const promises = map(data, (candidate, office) => {
        const vote = {
          election: this.currentElection._id,
          candidate,
          office,
        }
        return apiRequest('POST', '/votes', {
          url: variables.API_URL,
          body: vote,
          headers: {
            authorization: `Bearer ${User.data._token}`
          }
        });
      });
      await Promise.all(promises);
      this.voting = false;
    } catch (error) {
      this.voting = false
      throw error;
    }
  },

  cancelVotes: async function (electionId, voteIds) {
    try {
      this.cancelingVotes = true;
      const body = {
        election: electionId,
        votes: voteIds,
      };
      const data = await apiRequest('POST', `/votes/cancel`, {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.cancelingVotes = false;
      return data;
    } catch (error) {
      this.cancelingVotes = false;
      throw error;
    }
  },

  cancelSameDeviceVotes: async function (electionId) {
    try {
      this.cancelingVotes = true;
      const body = {
        election: electionId,
      };
      const data = await apiRequest('POST', `/votes/same_device/cancel`, {
        url: variables.API_URL,
        body,
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.cancelingVotes = false;
      return data;
    } catch (error) {
      this.cancelingVotes = false;
      throw error;
    }
  },

  loadElectionVotes: async function (electionId) {
    try {
      this.loadingElectionVotes = true;
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: {
          user: User.data._id
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      const data = await apiRequest('GET', '/votes', {
        url: variables.API_URL,
        query: {
          election: electionId, account: account._id,
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      });
      this.currentElectionVotes = data;
      this.loadingElectionVotes = false;
    } catch (error) {
      this.loadingElectionVotes = false;
      throw error;
    }
  },

  downloadElectionVotes: async function (electionId) {
    try {
      this.downloadingElectionVotes = true;

      await apiDownload('GET', '/votes/download', {
        url: variables.API_URL,
        query: {
          election: electionId,
        },
        headers: {
          authorization: `Bearer ${User.data._token}`
        }
      }, 'votes.json');

      this.downloadingElectionVotes = false;
    } catch (error) {
      this.downloadingElectionVotes = false;
      throw error;
    }
  },
}

export default observable(Manager);

import axios from 'axios';

class Config {
  configURL;
  group;
  token;

  API_URL;
  TITLE;
  LOGO;
  FAVICON;
  EMAIL_DOMAIN;
  WEBSITE_URL;
  CODE_FILTER;

  constructor(configURL, group) {
    this.configURL = configURL;
    this.group = group;
  }

  async load() {
    try {
      if (!this.token) {
        const { data } = await axios({
          method: 'GET',
          url: `${this.configURL}/guestLogin`,
          auth: {
            username: group,
            password: group,
          }
        });
        this.token = data._token;
      }
      const { data } = await axios({
        method: 'GET',
        url: `${this.configURL}/settings`,
        headers: {
          authorization: `Bearer ${this.token}`,
        },
        params: {
          group: this.group,
          _select: 'name,data',
        },
      });
      data.forEach((setting) => {
        if (setting.name === 'baseURL') this.API_URL = setting.data;
        if (setting.name === 'testBaseURL' && process.env.REACT_APP_TEST_MODE) this.API_URL = setting.data;
        if (setting.name === 'websiteURL') this.WEBSITE_URL = setting.data;
        if (setting.name === 'testWebsiteURL' && process.env.REACT_APP_TEST_MODE) this.WEBSITE_URL = setting.data;
        if (setting.name === 'title') this.TITLE = setting.data;
        if (setting.name === 'emailDomain') this.EMAIL_DOMAIN = setting.data;
        if (setting.name === 'codeFilter') this.CODE_FILTER = setting.data;
        if (setting.name === 'logo') {
          this.LOGO = setting.data.url;
          this.FAVICON = setting.data.thumb;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
}

const getHostname = () => {
  const hostname = window.location.hostname.toLowerCase().split('.');
  if (hostname.length > 2) {
    return `${hostname[hostname.length - 2]}.${hostname[hostname.length - 1]}`;
  }
  return hostname.join('.');
}

const hostname = getHostname();
const testHostnames = ['localhost', 'ngrok-free.app']
const group = testHostnames.indexOf(hostname) >= 0 ? process.env.REACT_APP_DEFAULT_GROUP : hostname;
const config = new Config(process.env.REACT_APP_CONFIG_URL, group);

export default config;

import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { Redirect } from 'react-router-dom';
import { pick } from 'lodash';
import { NotificationManager } from 'react-notifications';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Manager from '../../models/manager';

import './style.css';

class ElectionCreate extends Component {
  data = observable({
    error: false,
    loading: true,
    created: undefined,
    startDate: new Date(),
    startTime: new Date(),
    endDate: new Date(Date.now() + 24 * 60 * 60 * 1000),
    endTime: new Date(Date.now() + 24 * 60 * 60 * 1000),
  });

  async componentDidMount() {
    try {
      await Manager.loadOffices();
      if (this.props.update) {
        this.data.startDate = new Date(Manager.currentElection.startDate);
        this.data.startTime = new Date(Manager.currentElection.startDate);
        this.data.endDate = new Date(Manager.currentElection.endDate);
        this.data.endTime = new Date(Manager.currentElection.endDate);
        this.data.title = Manager.currentElection.title;
        this.data.tokenExtensionMins = Manager.currentElection.tokenExtensionMins;
      }
      this.data.loading = false;
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  async create() {
    try {
      this.data.startDate.setHours(this.data.startTime.getHours());
      this.data.startDate.setMinutes(this.data.startTime.getMinutes());
      this.data.startDate.setSeconds(0);
      this.data.startDate.setMilliseconds(0);
      this.data.endDate.setHours(this.data.endTime.getHours());
      this.data.endDate.setMinutes(this.data.endTime.getMinutes());
      this.data.endDate.setSeconds(0);
      this.data.endDate.setMilliseconds(0);
      const data = toJS(this.data);
      if (!data.title) {
        NotificationManager.error('title is required');
        return;
      }
      if (this.props.update) {
        const election = await Manager.updateElection(pick(data, ['title', 'tokenExtensionMins', 'startDate', 'endDate']));
        this.data.created = election;
        NotificationManager.success('updated election');
      } else {
        const election = await Manager.createElection(pick(data, ['title', 'tokenExtensionMins', 'startDate', 'endDate']));
        this.data.created = election;
      }
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    if (this.data.error) return (
      <Redirect to="/home" />
    )
    if (this.data.created && !this.props.update) return (
      <Redirect to={`/home/elections/${this.data.created._id}`} />
    )
    return (
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className="element-wrapper">
            <div className="element-box">
              <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
                <div className="element-info">
                  <div className="element-info-with-icon">
                    <div className="element-info-icon">
                      <div className="os-icon os-icon-fingerprint"></div>
                    </div>
                    <div className="element-info-text">
                      <h5 className="element-inner-header">
                        New Election Creation Form
                      </h5>
                      <div className="element-inner-desc">
                        Ensure you fill in the correct data
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Title</label>
                      <input
                        className="form-control"
                        placeholder="Enter Title"
                        defaultValue={this.data.title}
                        onChange={(e) => this.data.title = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Token Expiry Delay (Minutes)</label>
                      <input
                        className="form-control"
                        placeholder="Enter Token Expiry Delay"
                        defaultValue={this.data.tokenExtensionMins}
                        type="number"
                        onChange={(e) => this.data.tokenExtensionMins = Number(e.target.value)}
                      />
                    </div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className="row">
                        <div className="col text-center">
                          <div className="form-group">
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              label="Start Date"
                              format="MM/dd/yyyy"
                              value={this.data.startDate}
                              onChange={date => this.data.startDate = date}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </div>
                        </div>
                        <div className="col text-center">
                          <div className="form-group">
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label="Time"
                              value={this.data.startTime}
                              onChange={date => this.data.startTime = date}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-center">
                          <div className="form-group">
                            <KeyboardDatePicker
                              margin="normal"
                              id="date-picker-dialog"
                              label="End Date"
                              format="MM/dd/yyyy"
                              value={this.data.endDate}
                              onChange={date => this.data.endDate = date}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                            />
                          </div>
                        </div>
                        <div className="col text-center">
                          <div className="form-group">
                            <KeyboardTimePicker
                              margin="normal"
                              id="time-picker"
                              label="Time"
                              value={this.data.endTime}
                              onChange={date => this.data.endTime = date}
                              KeyboardButtonProps={{
                                'aria-label': 'change time',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </MuiPickersUtilsProvider>
                    {/* <div className="form-group">
                      <label htmlFor="">Start Date</label>
                      <input
                        className="form-control"
                        placeholder="Enter Start Date"
                        type="date"
                        onChange={(e) => this.data.startDate = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">End Date</label>
                      <input
                        className="form-control"
                        placeholder="Enter End Date"
                        type="date"
                        onChange={(e) => this.data.endDate = e.target.value}
                      />
                    </div> */}
                    <div className="form-buttons-w">
                      <button
                        className="btn btn-primary"
                        onClick={this.create.bind(this)}
                        disabled={Manager.creatingElection || Manager.updatingElection}
                      >
                        {this.props.update ? 'Update' : 'Create'}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ElectionCreate;

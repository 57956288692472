import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { pick, map, filter } from 'lodash';
import { NotificationManager } from 'react-notifications';

import Manager from '../../models/manager';

import './style.css';

const validateObject = (keys, obj) => {
  for (let i = 0; i < keys.length; i += 1) {
    const prop = keys[i];
    if (!obj[prop]) {
      NotificationManager.error(`${prop} is required`);
      return false;
    }
  }
  return true;
}

class ManageOffice extends Component {
  data = observable({
    error: false,
    loading: true,
    created: undefined,
    selectedOffices: [],
    currentOffice: {},
  });

  async componentDidMount() {
    try {
      await Manager.loadOffices();
      this.data.selectedOffices = toJS(Manager.currentElection.offices);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async create() {
    try {
      const data = toJS(this.data.currentOffice);
      const dataKeys = ['title'];
      if (!validateObject(dataKeys, data)) return;
      const createData = {
        ...pick(data, ['title', 'description', 'position']),
      };
      await Manager.createOffice(createData);
      NotificationManager.success('New office created');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async update() {
    try {
      const data = toJS(this.data.currentOffice);
      const dataKeys = ['title'];
      if (!validateObject(dataKeys, data)) return;
      const updateData = {
        ...pick(data, ['title', 'description', 'position']),
      };
      await Manager.updateOffice(data._id, updateData);
      NotificationManager.success('Office updated');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async delete() {
    try {
      const { _id, title } = this.data.currentOffice;
      await Manager.updateOffice(_id, { title: `${title}:DELETED on ${Date.now()}`, status: 'DELETED' });
      NotificationManager.success('Office deleted');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async updateElection() {
    const data = {
      offices: map(this.data.selectedOffices, (o) => o._id),
    }
    try {
      await Manager.updateElection(data);
      NotificationManager.success('Election offices updated');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  addToElection() {
    const current = toJS(this.data.currentOffice);
    this.data.selectedOffices = filter(toJS(this.data.selectedOffices), (o) => o._id !== current._id);
    this.data.selectedOffices.push(current);
  }

  removeOffice(office) {
    const newData = filter(toJS(this.data.selectedOffices), (i) => i._id !== office._id);
    this.data.selectedOffices = newData;
  }

  render() {
    return (
      <div className="row padded">
        <div className="col padded">
          <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
            <div className="element-info">
              <div className="element-info-with-icon">
                <div className="element-info-icon">
                  <div className="os-icon os-icon-fingerprint"></div>
                </div>
                <div className="element-info-text">
                  <h5 className="element-inner-header">
                    New Office Creation Form
                  </h5>
                  <div className="element-inner-desc">
                    Ensure you fill in the correct data
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <div className="col-md-12 padded">
                <h6>Current Election Offices</h6>
                <div className="b-b">
                  {
                    map(this.data.selectedOffices, (office) => (
                      <button
                        className={`btn mb-2 btn-danger btn-rounded animated fadeIn`}
                        onClick={() => this.removeOffice(office)}
                      >
                        <i className="os-icon os-icon-ui-15"></i>
                        <span>{office.title}</span>
                      </button>
                    ))
                  }
                  {
                    this.data.selectedOffices.length < 1 && (
                      <p>No offices have been added</p>
                    )
                  }
                </div>
                <div>
                  <button
                    className={`btn mt-2 btn-primary btn-rounded animated fadeIn`}
                    onClick={this.updateElection.bind(this)}
                  >
                    <span>Save Changes</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center mb-4">
              <div className="col-md-12 b-b padded">
                <h6>Available Offices</h6>
                <div>
                  {
                    map(Manager.offices, (office) => (
                      <button
                        className={`btn mb-2 ${this.data.currentOffice._id === office._id ? 'btn-success' : 'btn-primary'} btn-rounded animated fadeIn`}
                        onClick={() => this.data.currentOffice = office}
                      >
                        <i className="os-icon os-icon-hierarchy-structure-2"></i>
                        <span>{office.title}</span>
                      </button>
                    ))
                  }
                  {
                    Manager.offices.length < 1 && (
                      <p>No offices have been created</p>
                    )
                  }
                  <button
                    className="btn mb-2 btn-rounded btn-secondary animated fadeIn"
                    onClick={() => this.data.currentOffice = {}}
                  >
                    <span>Add New Office</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">Title</label>
                  <input
                    className="form-control"
                    placeholder="Title"
                    onChange={(e) => this.data.currentOffice.title = e.target.value}
                    value={this.data.currentOffice.title}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Description</label>
                  <input
                    className="form-control"
                    placeholder="Description"
                    onChange={(e) => this.data.currentOffice.description = e.target.value}
                    value={this.data.currentOffice.description}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Position</label>
                  <input
                    className="form-control"
                    placeholder="Position"
                    type="number"
                    onChange={(e) => this.data.currentOffice.position = Number(e.target.value)}
                    value={this.data.currentOffice.position}
                  />
                </div>
                <div className="form-buttons-w">
                  {
                    !this.data.currentOffice._id && (
                      <button
                        className="btn btn-primary"
                        onClick={this.create.bind(this)}
                        disabled={Manager.creatingOffice}
                      >
                        Create
                      </button>
                    )
                  }
                  {
                    this.data.currentOffice._id && (
                      <button
                        className="btn btn-primary"
                        onClick={this.update.bind(this)}
                        disabled={Manager.updatingOffice}
                      >
                        Update
                      </button>
                    )
                  }
                  {
                    this.data.currentOffice._id && (
                      <button
                        className="btn btn-primary"
                        onClick={this.addToElection.bind(this)}
                        disabled={Manager.updatingOffice}
                      >
                        Add To Election
                      </button>
                    )
                  }
                  {
                    this.data.currentOffice._id && (
                      <button
                        className="btn btn-danger"
                        onClick={this.delete.bind(this)}
                        disabled={Manager.updatingOffice}
                      >
                        Delete
                      </button>
                    )
                  }
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ManageOffice;

import React from 'react';
import { isPast } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { isNaN } from 'lodash';

import User from '../../models/user';
import Timer from '../Timer';
import { ElectionCandidateWinner } from '../../components/Election';
import WithURILoad from '../../components/WithURILoad'

export const ElectionRowItem = ({ data: {
  _id,
  title,
  status,
  description,
  startDate,
  endDate,
  offices,
  cancellationReason,
} }) => (
  <div className="element-box">
    <div className="element-info">
      <div className="row align-items-center">
        <div className="col-sm-9">
          <div className="element-info-with-icon">
            <div className="element-info-icon">
              <div className="os-icon os-icon-fingerprint"></div>
            </div>
            <div className="element-info-text">
              {
                status === 'DEFAULT' ? (
                  <h5 className="element-inner-header">
                    {title}
                  </h5>
                ) : (
                  <h5 className="element-inner-header text-danger">
                    {status}: {title}
                  </h5>
                )
              }
              {
                status === 'CANCELLED' ? (
                  <h6 className="element-inner-header text-secondary">
                    <b>Reason:</b> {cancellationReason}
                  </h6>
                ) : undefined
              }
              <div className="element-inner-desc">
                {description}
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-3 text-right">
          <NavLink to={`/home/elections/${_id}`} className="btn btn-primary btn-rounded">
            <i className="os-icon os-icon-ui-46" />
            Manage
          </NavLink>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-4 col-xl-4">
        <div className="row">
          <div className="col-sm-6 b-r">
            <div className="el-tablo centered padded">
              <div className="value">
                {offices.length}
              </div>
              <div className="label">
                Offices
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="el-tablo centered padded">
              <div className="value">
                <WithURILoad
                  URI="/candidates"
                  cache={`electionCandidates:${_id}`}
                  cacheTime={10000}
                  requestConfig={User.getRequestConfig({
                    query: { _count: true, election: _id, status: 'ACTIVE' }
                  })}>
                  {
                    ({ URIData, URILoading, URIError }) => {
                      if (URILoading || URIError) return (
                        <span>...</span>
                      )
                      return (<span>{isNaN(Number(URIData)) ? 0 : URIData}</span>)
                    }
                  }
                </WithURILoad>
              </div>
              <div className="label">
                Candidates
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-8 col-xl-8 b-l">
        <div className="padded">
          <div className="row">
            <div className="col-md-4">
              <div className="element-info-with-icon smaller">
                <div className="element-info-icon">
                  {isPast(new Date(endDate)) ? (
                    <div className="os-icon os-icon-hierarchy-structure-2"></div>
                  ) : (
                    <div className="os-icon os-icon-calendar-time"></div>
                  )}
                </div>
                <div className="element-info-text">
                  <h5 className="element-inner-header">
                    {isPast(new Date(endDate)) ? 'Result Summary' : isPast(new Date(startDate)) ? 'Time Left' : 'Countdown'}
                  </h5>
                  <div className="element-inner-desc">
                    {isPast(new Date(endDate)) ? 'Brief summary of winner' : isPast(new Date(startDate)) ? 'Time left to end of election' : 'Time left before election starts'}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {isPast(new Date(endDate)) ? (
                <div className="text-center">
                  {
                    offices[0] && (
                      <WithURILoad
                        URI="/results"
                        cache={`electionResult:${_id}`}
                        cacheTime={60000}
                        requestConfig={User.getRequestConfig({
                          query: {
                            office: offices[0],
                            election: _id,
                            _sort: 'votes:-1',
                            _limit: 2,
                            _populate: 'candidate,office'
                          }
                        })}>
                        {
                          ({ URIData, URILoading, URIError }) => {
                            if (URILoading || URIError) return (
                              <span>...</span>
                            )
                            const data1 = URIData[0];
                            const data2 = URIData[1];

                            if (data1 && data2 && data1.votes === data2.votes) {
                              return (
                                <>
                                  <ElectionCandidateWinner data={data1.candidate} office={data1.office.title} votes={data1.votes} />
                                  <ElectionCandidateWinner data={data2.candidate} office={data2.office.title} votes={data2.votes} />
                                </>
                              );
                            } else if (data1) {
                              return (
                                <ElectionCandidateWinner data={data1.candidate} office={data1.office.title} votes={data1.votes} />
                              );
                            }

                            return null;
                          }
                        }
                      </WithURILoad>
                    )
                  }
                </div>
              ) : isPast(new Date(startDate)) ? <Timer date={endDate} /> : <Timer date={startDate} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

import React, { Component } from 'react';
import { isPast } from 'date-fns';
import { Redirect, NavLink } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { observable } from 'mobx';
import { map, filter, get, reduce, find } from 'lodash';

import Manager from '../../models/manager';
import Timer from '../../components/Timer';
import Loader from '../../components/Loader';

import './style.css';

class Analytics extends Component {
  data = observable({
    error: false,
    loading: true,
    deleted: false,
    showManageCandidate: false,
    currentOffice: undefined,
    currentCandidate: undefined,
    showManageOffice: false,
    showUpdate: false,
    showDelete: false,
    showCandidateDetails: false,
  });

  async componentDidMount() {
    try {
      const { match: { params: { id } } } = this.props;
      await Manager.loadElection(id);
      this.loading = false;
      if (!isPast(new Date(Manager.currentElection.endDate)) && isPast(new Date(Manager.currentElection.startDate))) {
        this.refresh();
      }
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
    this.unmounted = true;
  }

  refresh() {
    this.refreshTimeout = setTimeout(() => {
      Manager.loadCurrentElectionResults();
      if (!this.unmounted) this.refresh();
    }, 10000);
  }

  render() {
    const { match: { params: { id } } } = this.props;

    if (this.data.error || this.data.deleted) {
      return (
        <Redirect to="/home" />
      )
    }

    if (Manager.loadingElection || !Manager.currentElection._id) {
      return <Loader show />;
    }

    return (
      <div>
        <div className="row align-items-center">
          <div className="col">
            <div className="element-info-with-icon smaller justify-content-center">
              <div className="element-info-icon">
                <div className="os-icon os-icon-calendar-time"></div>
              </div>
              <div className="element-info-text">
                <h5 className="element-inner-header">
                  Time Left
                </h5>
                <div className="element-inner-desc">
                  Time left to end of election
                </div>
              </div>
            </div>
            <Timer date={Manager.currentElection.endDate} />
          </div>
        </div>
        <div className="row mt-4">
          {
            map(Manager.currentElection.offices, (office) => {
              const results = filter(Manager.currentElectionResults, (r) => r.office === office._id);
              const totalVotes = reduce(results, (prev, curr) => curr.votes + prev, 0);
              return (
                <div key={office._id} className="col-lg-3">
                  <h4 className="padded b-b">{office.title}</h4>
                  <div>
                    {
                      map(filter(Manager.currentElectionCandidates, (c) => c.office === office._id), (candidate) => {
                        const result = find(results, (r) => r.candidate === candidate._id);
                        const votes = result ? result.votes : 0;
                        const percentage = votes / totalVotes * 100;
                        return (
                          <div key={candidate._id} className="row padded">
                            <div className="col-3">
                              <NavLink to={`/home/elections/${id}/analytics/${candidate._id}`} class="avatar-candidate" onClick={() => {
                                console.log(candidate);
                                this.data.currentCandidate = candidate;
                                this.data.showCandidateDetails = true
                              }}>
                                <img alt="" src={get(candidate, 'avatar.url') || "/img/avatar1.jpg"} />
                              </NavLink>
                            </div>
                            <div className="col-9">
                              <div class="os-progress-bar primary">
                                <div class="bar-labels">
                                  <div class="bar-label-left">
                                    <span>{candidate.firstName}</span><span>{candidate.lastName}</span>
                                  </div>
                                  <div class="bar-label-right">
                                    <span class="info">{votes}/{totalVotes}</span>
                                  </div>
                                </div>
                                <div class="bar-level-2" style={{ width: '100%' }}>
                                  <div class="bar-level-3" style={{ width: `${percentage}%` }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    }
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default Analytics;

import React, { Component } from 'react';
import { observable } from 'mobx';
import { map, isNaN } from 'lodash';

import User from '../../models/user';
import WithURILoad from '../../components/WithURILoad';

import './style.css';

const departments = require('../../assets/departments.json');

class AnalyticsBreakDown extends Component {
  data = observable({
    error: false,
    loading: true,
  });

  render() {
    const { match: { params: { election, candidate } } } = this.props;

    return (
      <div className="row">
        {
          map(departments, (name, deptId) => {
            return (
              <WithURILoad
                URI="/votes"
                requestConfig={User.getRequestConfig({
                  query: { election, candidate, identifier: `/\\d{4}${deptId}\\d{3,}/i`, _count: true }
                })}
              >
                {
                  ({ URIData, URILoading, URIError }) => {
                    if (URILoading || URIError) return null;
                    const votes = isNaN(Number(URIData)) ? 0 : Number(URIData);                    
                    return (
                      <div className="col-md-2">
                        <a key={deptId} className="element-box el-tablo centered trend-in-corner smaller" href="# ">
                          <div class="label">
                            {name}
                          </div>
                          <div class={`value ${votes > 0 ? 'text-success' : ''}`}>
                            {votes}
                          </div>
                        </a>
                      </div>
                    )
                  }
                }
              </WithURILoad>
            )
          })
        }
      </div>
    );
  }
}

export default AnalyticsBreakDown;

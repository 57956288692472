import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { observable } from 'mobx';
import { NotificationManager } from 'react-notifications';

import User from '../../models/user';
import Variables from '../../variables';

class Login extends Component {
  data = observable({
    show: 'login'
  });

  UNSAFE_componentWillMount() {
    document.body.className = "auth-wrapper";
    document.getElementById('root').className = "all-wrapper menu-side with-pattern";
  }

  handleChange = (name, ev) => {
    this.data[name] = ev.target.value;
  };

  async login(e) {
    try {
      e.preventDefault();
      await User.login(this.data.userId, this.data.password);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  async recover(e) {

  }

  render() {
    if (User.loggedIn) {
      return (
        <Redirect to='/home' />
      );
    }
    return (
      <>
        <div className="auth-box-w animated zoomIn">
          <div className="logo-w">
            <a href={Variables.WEBSITE_URL}><img alt="" width="100px" src={Variables.LOGO} /></a>
          </div>
          <h4 className="auth-header">
            Login
          </h4>
          {
            this.data.show === 'login' &&
            <form action="" onSubmit={this.login.bind(this)}>
              <div className="form-group">
                <label htmlFor="">User ID</label>
                <input
                  className="form-control"
                  placeholder="User ID"
                  defaultValue={this.data.userId}
                  onChange={this.handleChange.bind(this, 'userId')}
                />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="form-group">
                <label htmlFor="">Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Your Password"
                  defaultValue={this.data.password}
                  onChange={this.handleChange.bind(this, 'password')}
                />
                <div className="pre-icon os-icon os-icon-fingerprint"></div>
              </div>
              <div className="buttons-w">
                <button
                  className="btn btn-primary"
                  disabled={User.loggingIn}
                  onClick={this.login.bind(this)}
                >
                  Log me in
                </button>
              </div>
              <br />
              <div className="row">
                <div className="col-md-12">
                  <a href="# " className="link" onClick={() => this.data.show = 'recover'}>
                    I forgot my password
                  </a>
                </div>
              </div>
            </form>
          }
          {
            this.data.show === 'recover' &&
            <form action="" onSubmit={(e) => { this.recover(); e.preventDefault(); }}>
              <div className="form-group has-feedback">
                <label htmlFor="">User ID</label>
                <input
                  className="form-control"
                  placeholder="User ID"
                  defaultValue={this.data.userId}
                  onChange={this.handleChange.bind(this, 'userId')}
                />
                <div className="pre-icon os-icon os-icon-user-male-circle"></div>
              </div>
              <div className="buttons-w">
                <button className="btn btn-primary" disabled={User.loggingIn}>Reset</button>
                <button className="btn btn-default float-right" disabled={User.loggingIn} onClick={() => this.data.show = 'login'}>Login</button>
              </div>
            </form>
          }
        </div>
      </>
    );
  }
}

export default Login;

import React, { Component } from 'react';
import { capitalize } from 'lodash';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { NotificationManager } from 'react-notifications';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Dropzone from 'react-dropzone';

import Manager from '../../models/manager';
import User from '../../models/user';
import Variables from '../../variables';

import './style.css';

class MailSend extends Component {
  data = observable({
    error: false,
    loading: false,
    created: undefined,
    loadingHTMLEditor: true,
    refresh: Date.now(),
    title: '',
    election: '',
    source: 'apitest',
    modeList: [],
    years: [],
    showUpload: false,
    uploading: false,
    showMailSend: false,
    emailBody: '<p>Enter your mail content here</p>',
    identifier: '',
  });

  async componentDidMount() {
    this.reader = new FileReader();
  }

  async handleUpload(file) {
    this.data.uploading = true;
    try {
      this.reader.readAsDataURL(file);
      const { url } = await User.uploadImage(file);
      this.data.source = url;
    } catch (error) {
      NotificationManager.error(error.message);
    }
    this.data.uploading = false;
  }

  async sendMail() {
    try {
      await Manager.sendMail({
        identifier: this.data.identifier,
        title: this.data.title,
        body: this.data.emailBody,
        mode: this.data.mode,
        modeList: this.data.modeList,
        source: this.data.source,
        years: this.data.years,
      });
      NotificationManager.success('Email send queued. Refresh table to see new mails');
      if (this.props.onClose) this.props.onClose();
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className="element-wrapper">
            <div className="element-box">
              <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
                <div className="element-info">
                  <div className="element-info-with-icon">
                    <div className="element-info-icon">
                      <div className="os-icon os-icon-mail"></div>
                    </div>
                    <div className="element-info-text">
                      <h5 className="element-inner-header">
                        Mail Sender
                      </h5>
                      <div className="element-inner-desc">
                        Send mail to an email list
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Identifier</label>
                      <input
                        className="form-control"
                        placeholder="Identifier"
                        defaultValue={this.data.identifier}
                        onChange={(e) => this.data.identifier = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Title</label>
                      <input
                        className="form-control"
                        placeholder="Title"
                        defaultValue={this.data.title}
                        onChange={(e) => this.data.title = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Data Source</label>
                      <select
                        className="form-control"
                        placeholder="Data Source"
                        onChange={(e) => {
                          this.data.showUpload = e.target.value === 'upload';
                          this.data.source = e.target.value === 'upload' ? '' : e.target.value;
                        }}
                      >
                        <option value="">Select Data Source</option>
                        {/* <option value="api">Student Data API</option>
              <option value="apitest">Student Data API(Test)</option> */}
                        <option value="upload">Excel Sheet</option>
                      </select>
                    </div>
                    <Observer
                      render={() => {
                        if (this.data.showUpload) {
                          return (
                            <Dropzone
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                              onDrop={(files) => {
                                this.handleUpload(files[0]);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="form-group">
                                  <label htmlFor="">File Selection</label>
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      <input
                                        className="form-control"
                                        placeholder="Click Here To Upload"
                                        readOnly
                                        value={this.data.source.startsWith('http') ? this.data.source : 'Click Here To Upload'}
                                      />
                                    </div>
                                  </section>
                                </div>
                              )}
                            </Dropzone>
                          )
                        }
                        return null;
                      }}
                    />
                    <div className="form-group">
                      <label htmlFor="">Mode Selection</label>
                      <select
                        className="form-control"
                        placeholder="Mode"
                        onChange={(e) => this.data.mode = e.target.value}
                      >
                        <option value="">Select Mode</option>
                        <option value="exempt">Exempt {capitalize(Variables.CODE_FILTER)}</option>
                        <option value="include">Include {capitalize(Variables.CODE_FILTER)}</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Regnumber Code Filter</label>
                      <input
                        className="form-control"
                        placeholder={`${capitalize(Variables.CODE_FILTER)} Codes (comma seperated)`}
                        onChange={(e) => this.data.modeList = e.target.value.split(',').map(a => a.trim())}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Email Body</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={this.data.emailBody}
                        onReady={(editor) => {
                          console.log("CKEditor5 React Component is ready to use!", editor);
                          this.data.loadingHTMLEditor = false;
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.data.emailBody = data;
                        }}
                      />
                    </div>
                    <div className="form-buttons-w">
                      <Observer
                        render={() => (
                          <button
                            className="btn btn-primary"
                            onClick={this.sendMail.bind(this)}
                            disabled={Manager.sendingMail || this.data.uploading || this.data.loadingHTMLEditor}
                          >
                            Send
                          </button>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MailSend;

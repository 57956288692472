import React, { Component } from 'react';
import { map, get, forEach, findIndex } from 'lodash';
import { Subject } from 'rxjs';
import ReactTable from "react-table";
import PropTypes from 'prop-types';
import "react-table/react-table.css";

import { apiRequest } from '../../services/request';

const defaultPageSize = 10;

const stripBrackets = (st) => {
  return st.replace('[', '').replace(']', '').trim();
}

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.loadStream = new Subject();
    this.state = {
      data: get(props, 'data', []),
      page: 0,
      pages: -1,
      loading: false,
      filtered: get(props, 'filtered', []),
      sorted: get(props, 'sorted', [])
    };
  }

  static propTypes = {
    path: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object),
    requestConfig: PropTypes.object
  }

  componentDidMount() {
    this.loadStream
      .subscribe(() => {
        this.loadData(this.props.path);
      });
    try {
      this.props.updateStream && this.props.updateStream.subscribe((payload) => {
        console.log(payload);
        let index = findIndex(this.state.data, (d) => d === payload.old);
        console.log(index)
        let data = { ...this.state.data };
        data[index] = payload.current;
        this.setState({ data });
      });
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (
        this.props.path
        && this.props.update !== prevProps.update
      )
      || (this.props.refresh !== prevProps.refresh)
    ) {
      this.loadData(this.props.path);
    }
  }

  componentWillUnmount() {
    this.done = true;
  }

  loadData(url) {
    const { requestConfig, path } = this.props;
    this.setState({ loading: true }, () => {
      let promises = [
        apiRequest('GET', path, {
          ...requestConfig,
          query: {
            ...requestConfig.query,
            ...this.getQueryParams()
          }
        }),
        apiRequest('GET', path, {
          ...requestConfig,
          query: {
            ...requestConfig.query,
            ...this.getQueryParams(true)
          }
        })
      ];
      Promise.all(promises)
        .then((res) => {
          !this.done && this.setState({ loading: false, data: res[0], pages: Math.ceil(res[1] / get(this.props, 'pageSize', defaultPageSize)) });
        })
        .catch((err) => {
          !this.done && this.setState({ loading: false });
        });
    });
  }

  getQueryParams(count) {
    let q = {};
    let override = {};

    if (count) {
      q = {
        _count: true
      };
    } else {
      q = {
        _limit: get(this.props, 'pageSize', defaultPageSize),
        _skip: this.state.page * get(this.props, 'pageSize', defaultPageSize)
      };
    }

    forEach(this.state.filtered, (f) => {
      if (!f.value) return;

      if (this.props.filter && this.props.filter[f.id] && this.props.filter[f.id][f.value]) {
        forEach(this.props.filter[f.id][f.value], (v, k) => {
          override[k] = v;
        })
      }
      if (['false', 'true'].indexOf(String(f.value)) > -1) {
        q[f.id] = f.value;
      }
      else if (f.value.match(/\[.*\]/)) {
        let parts = f.value.split('to');
        if (parts.length > 1) {
          q[f.id] = JSON.stringify({ $gte: stripBrackets(parts[0]), $lte: stripBrackets(parts[1]) });
        } else {
          q[f.id] = JSON.stringify({ $gte: stripBrackets(parts[0]) });
        }
      }
      else if (String(f.value).length > 0) {
        if (String(f.value).match(/\{.*\}/i)) {
          q[f.id] = String(f.value).replace(/(\{|\})/g, '');
        } else {
          if (this.props.ignoreParsing && this.props.ignoreParsing.indexOf(f.id) >= 0) {
            q[f.id] = f.value;
          } else {
            q[f.id] = `/${f.value}/i`;
          }
        }
      }
    });

    let sorted = map(this.state.sorted, (s) => {
      return `${s.id}:${s.desc ? -1 : 1}`;
    });

    if (sorted && sorted.length > 0) {
      q._sort = sorted.join(',');
    }

    return { ...this.props.params, ...q, ...override };
  }

  table(columns, data) {
    if (this.props.data) {
      return (
        <ReactTable
          data={data}
          columns={columns}
          defaultPageSize={get(this.props, 'pageSize', defaultPageSize)}
          filterable={this.props.filterable}
          sortable
          showPaginationBottom={this.props.showPagination}
          className="-striped -highlight"
          defaultSorted={this.state.sorted}
          defaultFiltered={this.state.filtered}
          SubComponent={this.props.subComponent}
          pages={Math.ceil(this.state.data.length / get(this.props, 'pageSize', defaultPageSize))}
        />
      );
    }
    return (
      <ReactTable
        loading={this.state.loading}
        data={data}
        columns={columns}
        manual
        defaultPageSize={get(this.props, 'pageSize', defaultPageSize)}
        showFilters={this.state.showFilters}
        filterable={this.props.filterable}
        sortable
        showPaginationBottom={this.props.showPagination}
        className="-striped -highlight"
        defaultSorted={this.state.sorted}
        defaultFiltered={this.state.filtered}
        pages={this.state.pages}
        SubComponent={this.props.subComponent}
        onFetchData={(state, instance) => {
          this.setState({ page: state.page, filtered: state.filtered, sorted: state.sorted }, () => {
            this.loadStream.next();
          })
        }}
      />
    );
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        {
          this.table(this.props.columns, this.state.data)
        }
      </div>
    );
  }
}

export default DataTable;

import React, { Component } from 'react';
import { observable, toJS } from 'mobx';
import { NotificationManager } from 'react-notifications';
import { observer } from 'mobx-react';

import Manager from '../../models/manager';
import Variables from '../../variables';

const validateObject = (keys, obj) => {
  for (let i = 0; i < keys.length; i += 1) {
    const prop = keys[i];
    if (!obj[prop]) {
      NotificationManager.error(`${prop} is required`);
      return false;
    }
  }
  return true;
}

class AddUser extends Component {
  data = observable({
    error: false,
    loading: true,
    created: undefined,
  });

  async create() {
    try {
      const data = toJS(this.data);
      const dataKeys = ['firstName', 'lastName', 'regNumber', 'password'];
      if (!validateObject(dataKeys, data)) return;
      const createData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: `${data.regNumber}@${Variables.EMAIL_DOMAIN}`,
        password: data.password,
      };
      const user = await Manager.createUser(createData);
      NotificationManager.success(`${createData.firstName} ${createData.lastName} with password: ${createData.password} created successfully`);
      this.props.onCreate && this.props.onCreate(user);
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    return (
      <div className="row padded">
        <div className="col padded">
          <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
            <div className="element-info">
              <div className="element-info-with-icon">
                <div className="element-info-text">
                  <h5 className="element-inner-header">
                    New User Creation Form
                  </h5>
                  <div className="element-inner-desc">
                    Ensure you fill in the correct data
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className="form-group">
                  <label htmlFor="">First Name</label>
                  <input
                    className="form-control"
                    placeholder="First Name"
                    onChange={(e) => this.data.firstName = e.target.value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Last Name</label>
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    onChange={(e) => this.data.lastName = e.target.value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Reg Number</label>
                  <input
                    className="form-control"
                    placeholder="Reg Number"
                    onChange={(e) => this.data.regNumber = e.target.value}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="">Password</label>
                  <input
                    className="form-control"
                    placeholder="Password"
                    onChange={(e) => this.data.password = e.target.value}
                  />
                </div>
                <div className="form-buttons-w">
                  <button
                    className="btn btn-primary"
                    onClick={this.create.bind(this)}
                    disabled={Manager.creatingUser}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default observer(AddUser);

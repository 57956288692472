import { observable } from 'mobx';

import { apiRequest, postFormData } from '../services/request';
import variables from '../variables';

const User = {
  initializing: true,
  loggedIn: false,
  loggingIn: false,
  resetingPassword: false,
  signingUp: false,
  updating: false,
  uploading: false,
  updatingPassword: false,
  loadingAccount: false,
  data: {},
  account: {},

  initialize: async function () {
    const userData = localStorage.getItem('user');
    if (userData) {
      this.data = JSON.parse(userData);
      this.loggedIn = true;
    }
    this.initializing = false;
  },

  login: async function (userId, password) {
    try {
      this.loggingIn = true;
      let email = `${userId}@${variables.EMAIL_DOMAIN}`;
      if (email.indexOf('@') >= 0) email = userId;
      const data = await apiRequest('GET', '/login', {
        url: variables.API_URL,
        headers: {
          authorization: `Basic ${btoa(`${email}:${password}`)}`
        }
      });
      if (data.role === 'user') throw new Error('Access denied for your account type');
      localStorage.setItem('user', JSON.stringify(data));
      this.loggingIn = false;
      this.data = data;
      this.loggedIn = true;
      return data;
    } catch (error) {
      this.loggingIn = false;
      if (error.message === 'email or password incorrect') {
        throw new Error('User id or password incorrect');
      }
      throw new Error(error.message);
    }
  },

  loadAccount: async function () {
    try {
      if (!this.data._token) return;
      this.loadingAccount = true;
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: { user: this.data._id },
        headers: {
          authorization: `Bearer ${this.data._token}`
        }
      });
      this.account = account;
      this.loadingAccount = false;
      return account;
    } catch (error) {
      this.loadingAccount = false;
      throw new Error(error.message);
    }
  },

  /**
   * 
   * @param {{
   * }} body 
   */
  signup: async function (body) {
    try {
      this.signingUp = true;
      const data = await apiRequest('POST', '/signup', {
        url: variables.API_URL,
        body
      });
      this.signingUp = false;
      return data;
    } catch (error) {
      this.signingUp = false;
      throw new Error(error.message);
    }
  },

  confirmEmail: async function (key) {
    try {
      this.loggingIn = true;
      const data = await apiRequest('POST', '/verify', {
        url: variables.API_URL,
        body: {
          key
        }
      });
      const [account] = await apiRequest('GET', '/accounts', {
        url: variables.API_URL,
        query: { user: data._id },
        headers: {
          authorization: `Bearer ${data._token}`
        }
      });
      localStorage.setItem('user', JSON.stringify({ ...data, account }));
      this.loggingIn = false;
      this.data = data;
      this.loggedIn = true;
      return data;
    } catch (error) {
      this.loggingIn = false;
      throw new Error(error.message);
    }
  },

  update: async function (update) {
    try {
      this.updating = true;
      const id = this.account._id;
      const result = await apiRequest('PUT', `/accounts/${id}`, {
        url: variables.API_URL,
        headers: {
          authorization: `Bearer ${this.data._token}`
        },
        body: update
      });
      this.account = result;
      this.updating = false;
      return result;
    } catch (error) {
      this.updating = false;
      throw new Error(error.message);
    }
  },

  updatePassword: async function (update) {
    try {
      this.updatingPassword = true;
      const id = this.data._id;
      const result = await apiRequest('PUT', `/users/${id}`, {
        url: variables.API_URL,
        headers: {
          authorization: `Bearer ${this.data._token}`
        },
        body: update
      });
      this.updatingPassword = false;
      return result;
    } catch (error) {
      this.updatingPassword = false;
      throw new Error(error.message);
    }
  },

  uploadImage: async function (file) {
    try {
      this.uploading = true;
      const result = await postFormData(`${variables.API_URL}/uploads`, { file }, {
        authorization: `Bearer ${this.data._token}`
      });
      this.uploading = false;
      return result;
    } catch (error) {
      this.uploading = false;
      throw new Error(error.message);
    }
  },

  logout: async function () {
    this.data = {};
    this.loggedIn = false;
    localStorage.removeItem('user');
    this.account = {};
  },

  getRequestConfig: function ({ query }) {
    return {
      url: variables.API_URL,
      query,
      headers: {
        authorization: `Bearer ${this.data._token}`
      }
    };
  }
};

export default observable(User);

import React, { Component } from 'react';
import { observable } from 'mobx';
import { Observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import Manager from '../../models/manager';

import './style.css';

class ElectionCancel extends Component {
  data = observable({
    error: false,
    loading: false,
    created: undefined,
    cancellationReason: Manager.currentElection.cancellationReason || 'Technical challenges',
    cancellationTitle: Manager.currentElection.cancellationTitle || 'SUG ELECTION UPDATE',
    cancellationBody: Manager.currentElection.cancellationBody || '<p>The election has been cancelled, due to technical issues.</p>',
    loadingHTMLEditor: true,
  });

  async componentDidMount() {

  }

  async cancel() {
    try {
      const election = await Manager.updateElection({
        status: 'CANCELLED',
        cancellationReason: this.data.cancellationReason,
        cancellationTitle: this.data.cancellationTitle,
        cancellationBody: this.data.cancellationBody,
      });
      this.data.created = election;
      NotificationManager.success('election cancelled');
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    if (this.data.error) return (
      <Redirect to="/home" />
    )
    if (this.data.created) return (
      <Redirect to={`/home/elections/${this.data.created._id}/codes`} />
    )
    return (
      <div className="row">
        <div className="col-lg-2"></div>
        <div className="col-lg-8">
          <div className="element-wrapper">
            <div className="element-box">
              <form id="formValidate" onSubmit={(e) => e.preventDefault()}>
                <div className="element-info">
                  <div className="element-info-with-icon">
                    <div className="element-info-icon">
                      <div className="os-icon os-icon-fingerprint"></div>
                    </div>
                    <div className="element-info-text">
                      <h5 className="element-inner-header">
                        Election Cancellation
                      </h5>
                      <div className="element-inner-desc">
                        NOTE! a cancellation mail would be triggered once by this process
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label htmlFor="">Cancellation Reason</label>
                      <input
                        className="form-control"
                        placeholder="Enter Reason"
                        defaultValue={this.data.cancellationReason}
                        onChange={(e) => this.data.cancellationReason = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Cancellation Email Title</label>
                      <input
                        className="form-control"
                        placeholder="Enter Title"
                        defaultValue={this.data.cancellationTitle}
                        onChange={(e) => this.data.cancellationTitle = e.target.value}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="">Cancellation Email Body</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={this.data.cancellationBody}
                        onReady={(editor) => {
                          console.log("CKEditor5 React Component is ready to use!", editor);
                          this.data.loadingHTMLEditor = false;
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          this.data.cancellationBody = data;
                        }}
                      />
                    </div>
                    <div className="form-buttons-w">
                      <Observer
                        render={() => (
                          <button
                            className="btn btn-primary"
                            onClick={this.cancel.bind(this)}
                            disabled={Manager.updatingElection || this.data.loadingHTMLEditor}
                          >
                            Cancel
                          </button>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ElectionCancel;

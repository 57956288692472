import React from 'react';
import { get, find } from 'lodash';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Manager from '../../models/manager';

import './style.css';

export const ElectionCandidate = observer(({ button, data }) => (
  <div className="user-component profile-tile text-left animated slideInRight fadeIn">
    <Link className="profile-tile-box" href="# ">
      <div className="pt-avatar-w">
        <img alt="" src={get(data, 'avatar.url') || "/img/avatar1.jpg"} />
      </div>
      <div className="pt-user-name">
        <span>{data.firstName}</span><br /><span>{data.lastName}</span>
      </div>
    </Link>
    <div className="profile-tile-meta">
      <ul>
        <li>
          Name:<strong>{data.firstName}&nbsp;{data.lastName}</strong>
        </li>
        <li>
          Number of votes:
          <strong>{get(find(Manager.currentElectionResults, { candidate: data._id }), 'votes', 0)}</strong>
        </li>
        <li>
          Reg No:<strong>{data.regNumber}</strong>
        </li>
      </ul>
      {button}
    </div>
  </div>
));

export const ElectionCandidateWinner = ({ data, votes, office }) => (
  <div className="user-component profile-tile text-left animated slideInRight fadeIn">
    <a className="profile-tile-box" href="# ">
      <div className="pt-avatar-w">
        <img alt="" src={get(data, 'avatar.url') || "/img/avatar1.jpg"} />
      </div>
      <div className="pt-user-name">
        <span>{data.firstName}</span><br /><span>{data.lastName}</span>
      </div>
    </a>
    <div className="profile-tile-meta">
      <ul>
        <li>
          Name:<strong>{data.firstName}&nbsp;{data.lastName}</strong>
        </li>
        <li>
          Number of votes:
          <strong>{votes}</strong>
        </li>
        <li>
          Reg No:<strong>{data.regNumber}</strong>
        </li>
        <li>
          Office:<strong>{office}</strong>
        </li>
      </ul>
    </div>
  </div>
);

import React, { Component } from 'react';
import { isPast } from 'date-fns';
import { NavLink, Redirect } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { observable, toJS } from 'mobx';
import { map, filter, find, orderBy } from 'lodash';
import ReactToPrint from 'react-to-print';

import Manager from '../../models/manager';
import Timer from '../../components/Timer';
import { ElectionCandidate, ElectionCandidateWinner } from '../../components/Election';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import { ManageCandidate, ManageOffice, Delete } from '.';
import ElectionCreate from './ElectionCreate';
import ElectionCancel from './ElectionCancel';

class ElectionView extends Component {
  data = observable({
    error: false,
    loading: true,
    deleted: false,
    showManageCandidate: false,
    currentOffice: undefined,
    currentCandidate: undefined,
    showManageOffice: false,
    showUpdate: false,
    showDelete: false,
    showCancel: false,
  });

  async componentDidMount() {
    this.loadData();
  }

  async loadData() {
    try {
      const { match: { params: { id } } } = this.props;
      await Manager.loadElection(id);
      this.loading = false;
      if (!isPast(new Date(Manager.currentElection.endDate)) && isPast(new Date(Manager.currentElection.startDate))) {
        this.refresh();
      }
    } catch (error) {
      this.data.error = true;
      NotificationManager.error(error.message);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.refreshTimeout);
    this.unmounted = true;
  }

  refresh() {
    this.refreshTimeout = setTimeout(() => {
      NotificationManager.info('refreshing data...');
      Manager.loadCurrentElectionResults();
      if (!this.unmounted) this.refresh();
    }, 60000);
  }

  downloadVotes() {
    try {
      if (!Manager.downloadingElectionVotes) {
        const { match: { params: { id } } } = this.props;
        Manager.downloadElectionVotes(id);
      }
    } catch (error) {
      NotificationManager.error(error.message);
    }
  }

  render() {
    const { match: { params: { id } } } = this.props;

    if (this.data.error || this.data.deleted) {
      return (
        <Redirect to="/home" />
      )
    }

    if (Manager.loadingElection || !Manager.currentElection._id) {
      return <Loader show />;
    }

    return (
      <>
        <Modal id="manageCandidate" show={this.data.showManageCandidate} onClose={() => this.data.showManageCandidate = false}>
          <ManageCandidate
            office={this.data.currentOffice}
            candidate={toJS(this.data.currentCandidate)}
            onCreate={() => this.data.showManageCandidate = false}
            onDelete={() => {
              this.loadData();
              this.data.showManageCandidate = false;
            }}
          />
        </Modal>
        <Modal id="manageOffice" show={this.data.showManageOffice} onClose={() => this.data.showManageOffice = false}>
          <ManageOffice />
        </Modal>
        <Modal id="delete" show={this.data.showDelete} onClose={() => this.data.showDelete = false}>
          <Delete onDelete={() => this.data.deleted = true} />
        </Modal>
        <Modal id="updateElection" show={this.data.showUpdate} onClose={() => this.data.showUpdate = false}>
          <ElectionCreate update />
        </Modal>
        <Modal id="cancelElection" show={this.data.showCancel} onClose={() => this.data.showCancel = false}>
          <ElectionCancel />
        </Modal>
        <div className="row align-items-center" ref={el => (this.printComponent = el)}>
          <div className="col">
            <div className="element-info-with-icon smaller justify-content-center">
              <div className="element-info-icon">
                {isPast(new Date(Manager.currentElection.endDate)) ? (
                  <div className="os-icon os-icon-hierarchy-structure-2"></div>
                ) : (
                  <div className="os-icon os-icon-calendar-time"></div>
                )}
              </div>
              <div className="element-info-text">
                <h5 className="element-inner-header">
                  {isPast(new Date(Manager.currentElection.endDate)) ? 'Result Summary' : isPast(new Date(Manager.currentElection.startDate)) ? 'Time Left' : 'Countdown'}
                </h5>
                <div className="element-inner-desc">
                  {isPast(new Date(Manager.currentElection.endDate)) ? 'Detailed summary of results' : isPast(new Date(Manager.currentElection.startDate)) ? 'Time left to end of election' : 'Time left before election starts'}
                </div>
              </div>
            </div>
            {isPast(new Date(Manager.currentElection.endDate)) ? (
              <div className="row">
                {
                  map(Manager.currentElection.offices, (office) => {
                    const results = orderBy(filter(Manager.currentElectionResults, (r) => r.office === office._id), 'votes', 'desc');
                    const winner1 = results[0];
                    const winner2 = results[1];
                    if (winner1 && winner2 && winner1.votes === winner2.votes) {
                      const candidate1 = find(Manager.currentElectionCandidates, { _id: winner1.candidate });
                      const candidate2 = find(Manager.currentElectionCandidates, { _id: winner2.candidate });
                      return (
                        <div key={candidate1._id}>
                          <div className="col text-md-center">
                            <ElectionCandidateWinner data={candidate1} office={office.title} votes={winner1.votes} />
                            <ElectionCandidateWinner data={candidate2} office={office.title} votes={winner2.votes} />
                          </div>
                        </div>
                      )
                    } else if (winner1) {
                      const candidate1 = find(Manager.currentElectionCandidates, { _id: winner1.candidate });
                      return (
                        <div className="col text-md-center" key={candidate1._id}>
                          <ElectionCandidateWinner data={candidate1} office={office.title} votes={winner1.votes} />
                        </div>
                      )
                    }
                  })
                }
              </div>
            ) : isPast(new Date(Manager.currentElection.startDate)) ? <Timer date={Manager.currentElection.endDate} /> : <Timer date={Manager.currentElection.startDate} />}
          </div>
        </div>
        {/* {
          Manager.canParticipateInCurrentElection && (
            <div className="row text-center mt-5">
              <div className="col">
                <NavLink to={`/home/elections/${id}/vote`} className="btn btn-primary btn-rounded animated bounce">
                  <i className="os-icon os-icon-fingerprint"></i>
                  <span>Cast Vote</span>
                </NavLink>
              </div>
            </div>
          )
        } */}
        <div className="row mt-5">
          <div className="col text-center">
            <button
              className="btn btn-primary btn-rounded animated bounce mb-1"
              onClick={() => this.data.showManageOffice = true}
            >
              <i className="os-icon os-icon-hierarchy-structure-2"></i>
              <span>Add Office Position</span>
            </button>
            <button
              className="btn btn-primary btn-rounded animated bounce mb-1"
              onClick={() => this.data.showUpdate = true}
            >
              <i className="os-icon os-icon-pencil-2"></i>
              <span>Update Election</span>
            </button>
            <button
              className="btn btn-primary btn-rounded animated bounce mb-1"
              onClick={() => this.data.showCancel = true}
            >
              <i className="os-icon os-icon-pencil-2"></i>
              <span>Cancel Election</span>
            </button>
            <NavLink
              className="btn btn-primary btn-rounded animated bounce mb-1"
              to={`/home/elections/${id}/codes`}
            >
              <i className="os-icon os-icon-fingerprint"></i>
              <span>Manage Codes</span>
            </NavLink>
            <NavLink
              className="btn btn-primary btn-rounded animated bounce mb-1"
              to={`/home/elections/${id}/votes`}
            >
              <i className="os-icon os-icon-check"></i>
              <span>Votes</span>
            </NavLink>
            <NavLink
              className="btn btn-primary btn-rounded animated bounce mb-1"
              to={`/home/elections/${id}/analytics`}
            >
              <i className="os-icon os-icon-bar-chart-stats-up"></i>
              <span>Analytics</span>
            </NavLink>
            <ReactToPrint
              pageStyle={{ backgroundColor: 'white' }}
              trigger={() => {
                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                // to the root node of the returned component as it will be overwritten.
                return (
                  <button
                    className="btn btn-primary btn-rounded animated bounce mb-1"
                  >
                    <i className="os-icon os-icon-printer"></i>
                    <span>Print Result</span>
                  </button>
                );
              }}
              content={() => this.printComponent}
            />
            <button
              className="btn btn-primary btn-rounded animated bounce mb-1"
              onClick={() => this.downloadVotes()}
              disabled={Manager.downloadingElectionVotes}
            >
              <i className="os-icon os-icon-download"></i>
              <span>Download Votes</span>
            </button>
            <button
              className="btn btn-danger btn-rounded animated bounce mb-1"
              onClick={() => this.data.showDelete = true}
            >
              <i className="os-icon os-icon-ui-15"></i>
              <span>Delete Election</span>
            </button>
          </div>
        </div>
        {
          map(Manager.currentElection.offices, (office) => (
            <div key={office._id} className="row align-items-center mt-5">
              <div className="col">
                <div className="row b-b mb-4">
                  <div className="col-8">
                    <h4 className="text-left">{office.title}</h4>
                  </div>
                  <div className="col-4 text-right">
                    <button className="btn btn-primary btn-sm btn-rounded" onClick={() => {
                      this.data.currentOffice = office._id;
                      this.data.currentCandidate = undefined;
                      this.data.showManageCandidate = true;
                    }}>
                      {/* <i className="os-icon os-icon-"></i> */}
                      <span>Add Candidate</span>
                    </button>
                  </div>
                </div>
                <div className="row">
                  {
                    map(filter(Manager.currentElectionCandidates, (c) => c.office === office._id), (candidate) => (
                      <div className="col text-md-center" key={candidate._id}>
                        <ElectionCandidate
                          data={candidate}
                          button={(
                            <div className="pt-btn">
                              <button
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  this.data.currentCandidate = candidate;
                                  this.data.showManageCandidate = true;
                                }}
                              >
                                <i className="os-icon os-icon-ui-46"></i>
                                <span>Manage</span>
                              </button>
                            </div>
                          )}
                        />
                      </div>
                    ))
                  }
                </div>
                {
                  filter(Manager.currentElectionCandidates, (c) => c.office === office._id).length <= 0 && (
                    <h5 className="text-center mt-5 mb-5" >No candidate available for this office</h5>
                  )
                }
              </div>
            </div>
          ))
        }
      </>
    );
  }
}

export default ElectionView;

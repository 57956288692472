import React from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react';
import { get } from 'lodash';

import User from '../../models/user';
import Variables from '../../variables';

const TopMenu = ({ onAction }) => (
  <>
    <div style={{ zIndex: 9999 }} className="menu-mobile menu-activated-on-click color-scheme-dark">
      <div className="mm-logo-buttons-w">
        <a className="mm-logo" href="/">
          <img src={Variables.LOGO} alt="" /><span>{Variables.TITLE}</span>
        </a>
        <div className="mm-buttons">
          <div className="content-panel-open">
            <div className="os-icon os-icon-grid-circles"></div>
          </div>
          <div className="mobile-menu-trigger">
            <div className="os-icon os-icon-hamburger-menu-1"></div>
          </div>
        </div>
      </div>
      <div className="menu-and-user">
        <div className="logged-user-w">
          <div className="avatar-w">
            <img alt="" src={get(User.account, 'avatar.url') || "/img/avatar1.jpg"} />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">
              {User.account.firstName || User.data.email.split('@')[0]}
            </div>
            <div className="logged-user-role">
              {User.data.role}
            </div>
          </div>
        </div>
        <ul className="main-menu">
          <li >
            <NavLink to="/home">
              <div className="icon-w">
                <div className="os-icon os-icon-layout"></div>
              </div>
              <span>Home</span>
            </NavLink>
          </li>
          <li >
            <NavLink to="/home/create-election">
              <div className="icon-w">
                <div className="os-icon os-icon-fingerprint"></div>
              </div>
              <span>Create Election</span>
            </NavLink>
          </li>
          <li >
            <a href="# " onClick={() => onAction && onAction('createUser')}>
              <div className="icon-w">
                <div className="os-icon os-icon-user-male-circle"></div>
              </div>
              <span>Create User</span>
            </a>
          </li>
          <li>
            <NavLink to="/home/mailer">
              <div className="icon-w">
                <div className="os-icon os-icon-mail"></div>
              </div>
              <span>Send Emails</span>
            </NavLink>
          </li>
          <li >
            <NavLink to="/home/profile">
              <div className="icon-w">
                <div className="os-icon os-icon-user-male-circle2"></div>
              </div>
              <span>Profile</span>
            </NavLink>
          </li>
          <li >
            <a href="# " onClick={() => User.logout()}>
              <div className="icon-w">
                <div className="os-icon os-icon-signs-11"></div>
              </div>
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    {/* <div className="menu-w color-scheme-light color-style-default menu-position-side menu-side-left menu-layout-mini sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
      <div className="logo-w">
        <a className="logo" href="/">
          <div className="logo-element"></div>
          <div className="logo-label">
            {Variables.TITLE}
          </div>
        </a>
      </div>
    </div> */}
    <div className="menu-w color-scheme-dark color-style-bright menu-position-top menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover">
      <div className="logo-w">
        <a className="logo" href="/">
          <img src={Variables.LOGO} alt="" />
          <div className="logo-label">
            {Variables.TITLE}
          </div>
        </a>
      </div>
      <div className="logged-user-w avatar-inline">
        <div className="logged-user-i">
          <div className="avatar-w">
            <img alt="" src={get(User.account, 'avatar.url') || "/img/avatar1.jpg"} />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">
              {User.account.firstName || User.data.email.split('@')[0]}
            </div>
            <div className="logged-user-role">
              {User.data.role}
            </div>
          </div>
          <div className="logged-user-toggler-arrow">
            <div className="os-icon os-icon-chevron-down"></div>
          </div>
          <div className="logged-user-menu color-style-bright">
            <div className="logged-user-avatar-info">
              <div className="avatar-w">
                <img alt="" src={get(User.account, 'avatar.url') || "/img/avatar1.jpg"} />
              </div>
              <div className="logged-user-info-w">
                <div className="logged-user-name">
                  {User.data.firstName || User.data.email}
                </div>
                <div className="logged-user-role">
                  {User.data.role}
                </div>
              </div>
            </div>
            <div className="bg-icon">
              <i className="os-icon os-icon-user-male-circle"></i>
            </div>
            <ul>
              <li>
                <NavLink to="/home/profile">
                  <i className="os-icon os-icon-user-male-circle2"></i><span>Profile</span>
                </NavLink>
              </li>
              <li>
                <a href="# " onClick={() => User.logout()}>
                  <i className="os-icon os-icon-signs-11"></i><span>Logout</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ul className="main-menu">
        <li className="sub-header">
          <span>Layouts</span>
        </li>
        <li className="selected">
          <NavLink to="/home">
            <div className="icon-w">
              <div className="os-icon os-icon-layout"></div>
            </div>
            <span>Home</span>
          </NavLink>
        </li>
        <li >
          <NavLink to="/home/create-election">
            <div className="icon-w">
              <div className="os-icon os-icon-fingerprint"></div>
            </div>
            <span>Create Election</span>
          </NavLink>
        </li>
        <li >
          <a href="# " onClick={() => onAction && onAction('createUser')}>
            <div className="icon-w">
              <div className="os-icon os-icon-user-male-circle"></div>
            </div>
            <span>Create User</span>
          </a>
        </li>
        <li >
          <NavLink to="/home/mailer">
            <div className="icon-w">
              <div className="os-icon os-icon-mail"></div>
            </div>
            <span>Send Emails</span>
          </NavLink>
        </li>
      </ul>
    </div>
  </>
);

export default observer(TopMenu);
